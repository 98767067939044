<template>
  <div>
    <div>
      <statistic-card @connect-bot="connectBot" v-if="currentUser" :user="currentUser" />
    </div>
    <Modal
      v-if="token"
      :show="showTokenModal"
      @hide="() => (showTokenModal = false)"
      title="Подтвердите аккаунт в боте"
      contentClass="promo-modal__content"
    >
      <h3 style="margin-bottom: 20px">
        Скопируйте код ниже в чат с
        <a target="_blank" class="link" href="https://t.me/leadprbot">@leadprbot</a>
      </h3>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <b-form-input class="w-50" disabled :value="connectCommand" />
        <b-button variant="warning" v-clipboard="connectCommand">Скопировать</b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import utilsApi from '@main/api/utils';
import Modal from '@main/components/reusable/modals/Modal.vue';
import StatisticCard from '../reusable/statistics/StatisticCard.vue';

export default {
  components: {
    StatisticCard,
    Modal,
  },
  data: () => ({
    showTokenModal: false,
    token: null,
  }),
  computed: {
    ...mapState(['currentUser']),
    connectCommand() {
      return `/tie site-join---${this.token}`;
    },
  },
  methods: {
    async connectBot() {
      this.showTokenModal = true;
      this.token = await utilsApi.getBotConnectToken();
    },
    getTg() {
      window.open(`https://t.me/leadprbot`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped></style>
